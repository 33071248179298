import { render, staticRenderFns } from "./Burger.vue?vue&type=template&id=71127025&scoped=true"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_prettier@3.1.0_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71127025",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71127025')) {
      api.createRecord('71127025', component.options)
    } else {
      api.reload('71127025', component.options)
    }
    module.hot.accept("./Burger.vue?vue&type=template&id=71127025&scoped=true", function () {
      api.rerender('71127025', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Burger.vue"
export default component.exports