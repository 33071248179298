<template>
	<div></div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'Home',

	components: {},
});
</script>
