/* eslint-disable max-len */
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.directive('ripple', {
	inserted: (el, binding) => {
		const color = binding.value === undefined ? '#fff' : binding.value;
		el.style.position = 'relative';
		el.style.overflow = 'hidden';
		el.onclick = (ev: MouseEvent) => {
			const circle = document.createElement('div');
			circle.setAttribute(
				'style',
				`background: ${color}; width: 32.5px; height: 32.5px; opacity: 0.2; border-radius: 50%; position: absolute; top: 10px;   animation: gz-ripple 0.4s 1 ease-in-out;`
			);
			el.appendChild(circle);
			ev.stopPropagation();
			setTimeout(() => {
				circle.parentNode?.removeChild(circle);
			}, 400);
		};
	},
});

const vueLifecycles = singleSpaVue({
	Vue,
	appOptions: {
		render(h: any) {
			return h(App, {
				props: {
					// single-spa props are available on the "this" object. Forward them to your component as needed.
					// https://single-spa.js.org/docs/building-applications#lifecyle-props
					// if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
					/*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
				},
			});
		},
		router,
	},
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
